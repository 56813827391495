@tailwind base;
@tailwind components;
@tailwind utilities;

.loader {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

ul li {
    list-style-type: disc;
}

ol li {
    list-style-type: decimal;
}

@media screen and (max-width: 768px) {
    ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
}

.dark-mode::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.toggle-checkbox:checked {
    @apply right-0 border-primary;
    right: 0;
}

.toggle-checkbox:checked + .toggle-label {
    @apply bg-accent;
}

@font-face {
    font-family: "Signika";
    src: url("/fonts/Signika.ttf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Dela Gothic One";
    src: url("/fonts/DelaGothicOne-Regular.ttf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

::-webkit-scrollbar {
    width: 0px;
    height: 1px;
}
